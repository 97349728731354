import React from 'react';
import LayoutIT from '../components/layoutIT';
import SEO from '../components/seo';
import AboutIT from '../containers/videoIT';

type AboutPageProps = {};

const AboutPage: React.FunctionComponent<AboutPageProps> = () => {
  return (
    <LayoutIT>
      <SEO
        title="Video dell'artista Sifrein"
        description="Alcuni video degli artisti della galleria Sifrein Paris"
      />

      <AboutIT />
    </LayoutIT>
  );
};

export default AboutPage;
